$(document).foundation();
var Site = {}; 

Site.carousel = {
	init : function(){
		console.info('init carousel');
		$scrolling = null;
		$('.feed-recommendations-updates').on('mouseover','.slick-arrow', function(){
				console.log('next');
				$th = $(this);
				$th.click();
				$scrolling = setInterval(function(){
					console.log('next interval');
					$th.click();
				}, 500);
			})
			.on('mouseout','.slick-arrow', function(){
					clearInterval($scrolling);
			});
		;
	}
};
Site.audio = {
	init: function(){
		$players = $('.community__audio');
			
		$players.each(function() {
			$th = $(this);
			var $audio = $th.find('.audio_player'), 
			
			$playButton = $th.find('.audio_play_btn'), 
			$pauseButton = $th.find('.audio_play_btn'), 

			$elapsed = $th.find('.audio__start'), 
			$totalTime = $th.find('.audio__end'), 
			$loadingIndicator = $th.find('.audio-loading'), 
			$audioMarker = $th.find('.audio__marker'),
			//$trackOuter = $th.find('.audio__track-outer'),
			manualSeek = false; 

			if(!!document.createElement('audio').canPlayType) {
				if (($audio.buffered != undefined) && ($audio.buffered.length != 0)) {
					// DO SOMETHING WHILE AUDIO IS LOADING?
					// $(audio).bind('progress', function() {
					//     var loaded = parseInt(((audio.buffered.end(0) / audio.duration) * 100), 10);
					//     loadingIndicator.css({width: loaded + '%'});
					// });
				}
				else {
					$loadingIndicator.remove();
					$audio.removeClass('hidden');  
				}

				$playButton.on('click', function() { 
					//$(this).addClass('hidden'); 
					//$pauseButton.removeClass('hidden'); 
					console.log('play');
					if(!$th.hasClass('playing'))
					{
						$th.addClass('playing');
						$audio[0].play(); 
					}
					else{
						$th.removeClass('playing');
						$audio[0].pause(); 	
					}

					// $loadingIndicator.remove(); // not sure bout this

					// jquery ui hack: 
					//$trackOuter.slider({ value: 0 }); 
					
					// $trackOuter.slider({
					// 	value: $audio[0].currentTime,
					// 	step: 0.01,
					// 	orientation: "horizontal",
					// 	range: "min",
					// 	max: $audio[0].duration,
					// 	animate: true,
					// 	slide: function() {
					// 		manualSeek = true;
					// 	},
					// 	stop: function(e, ui) {
					// 		manualSeek = false;
					// 		$audio[0].currentTime = ui.value;
					// 	}
					// });

					// var duration = parseInt($audio[0].duration, 10),
					// 	durationMins = Math.floor(duration/60,10),
					// 	durationSecs = parseInt(duration - durationMins*60); 
					
					// $totalTime.text(durationMins + ':' + (durationSecs > 9 ? durationSecs : '0' + durationSecs));
				}); 

				// $pauseButton.on('click', function() { 
				// 	$(this).addClass('hidden'); 
				// 	$playButton.removeClass('hidden'); 
				// 	$audio[0].pause(); 
				// }); 

				$audio.bind('timeupdate', function() { 
					var pos = ($audio[0].currentTime / $audio[0].duration) * 100, 
						elapsed = parseInt($audio[0].currentTime, 10), 
						elapsedMins = Math.floor(elapsed/60,10), 
						elapsedSecs = elapsed - elapsedMins*60; 

					$elapsed.text(elapsedMins + ':' + (elapsedSecs > 9 ? elapsedSecs : '0' + elapsedSecs));

					// new for manual seek (ui slider)
					if (!manualSeek) { 
						$audioMarker.css({left: pos + '%'}); 
						$('.ui-slider-range-min').css('width', pos + '%'); 
					}
				});
			}
		});	
	}
};
Site.videos = { 
  init: function() { 
   // Site.sameHeight($('.video-box'), $('h3')); 
   console.log('init');
  }, 

  ytPlayers: new Array(), 
  vimPlayers: new Array(), 
  ytInit: function() { 
    var tag = document.createElement('script');

    tag.src = "https://www.youtube.com/iframe_api";
    var firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
  }, 

  ytReady: function(modalID, vidID) {
    var ytVid = new YT.Player(modalID, {
      height: '390',
      width: '640',
      videoId: vidID,
      events: {
        'onReady': onPlayerReady
      }
    });

    var index = Site.videos.ytPlayers.indexOf(ytVid);

    if(index == -1) { 
      Site.videos.ytPlayers.push(ytVid); 
    }

    function onPlayerReady(event) {
      Site.videos.ytPauseVids(); 
      Site.videos.vimPauseVids(); 

      // play video
      event.target.playVideo();
    }
  }, 

  ytPauseVids: function() { 
    for(var i = 0; i < Site.videos.ytPlayers.length; i++) { 
      Site.videos.ytPlayers[i].pauseVideo(); 
    }
  }, 

  ytDestroyVids: function() { 
    for(var i = 0; i < Site.videos.ytPlayers.length; i++) { 
      if (Site.videos.ytPlayers[i].a.id == 'ModalVideoOuter') { 
        Site.videos.ytPlayers[i].destroy();
        Site.videos.ytPlayers.splice(i, 1); 
      }
    }
  }, 

  vimeoReady: function(modalID, vidID) { 
    Site.videos.ytPauseVids(); 
    Site.videos.vimPauseVids(); 

    var options = {
      id: vidID,
      width: 640,
      loop: false, 
      autoplay: true
    };

    var player = new Vimeo.Player(modalID, options), 
      index = Site.videos.vimPlayers.indexOf(player);

    if(index == -1) { 
      Site.videos.vimPlayers.push(player); 
    }

    // stupid redundant stuff because autoplay doesn't work when vimeo is re-initialized???
    player.play();
  }, 

  vimPauseVids: function() { 
    // pause vids
    for(var i = 0; i < Site.videos.vimPlayers.length; i++) { 
      Site.videos.vimPlayers[i].pause();
    }
  }, 

  vimDestroyVids: function() { 
    // unset
    for(var i = 0; i < Site.videos.vimPlayers.length; i++) { 
      if (Site.videos.vimPlayers[i].element.src.toString() == $('#ModalVideoOuter iframe').attr('src').toString()) { 
        Site.videos.vimPlayers[i].unload().then(function() { 
          $('#ModalVideoOuter iframe').remove(); 
        }); 
        Site.videos.vimPlayers.splice(i, 1); 
      }
    }
  }, 

  inline: function() { 
    $('.inline-video-outer').on('click', function(e) { 
      e.preventDefault(); 
      $(this).addClass('activated')
      var ytID = $(this).attr('data-yt-id'), 
        vimeoID = $(this).attr('data-vim-id');

      if(ytID) { 
        Site.videos.ytReady('InlineVideo' + ytID, ytID); 
      }

      if(vimeoID) { 
        Site.videos.vimeoReady('InlineVideo' + vimeoID, vimeoID); 
      }
    }); 
  }, 

  hero: function() { 
    $('.btn--hero-video').on('click', function(e) { 
      e.preventDefault(); 

      var ytID = $(this).attr('data-yt-id'), 
        vimeoID = $(this).attr('data-vim-id');

      if(ytID) { 
        Site.videos.ytReady('HeroVideo' + ytID, ytID); 
      }

      if(vimeoID) { 
        Site.videos.vimeoReady('HeroVideo' + vimeoID, vimeoID); 
      }

      // because
      var $heroBg = $(this).closest('.hero-bg-img'); 
      $heroBg.addClass('video-open'); 
    }); 
  }, 

  modal: function() { 
    $('a[href="#VideoModal"]').on('click', function(e) { 
      e.preventDefault();
      $th = $(this);
      
      var ytID = $th.attr('data-yt-id'), 
        vimeoID = $th.attr('data-vim-id'),
        iframe = $th.attr('data-iframe');

      // youtube or vimeo
      if(ytID) { 
        Site.videos.ytReady('ModalVideoOuter', ytID); 
      }
      else if(vimeoID) { 
        $('#ModalVideoOuter').html('<div id="ModalVideo' + vimeoID + '"></div>'); 
        Site.videos.vimeoReady('ModalVideo' + vimeoID, vimeoID); 
      }
      else if(iframe){
        
        $('#ModalVideoOuter').html($('#' + iframe).html());
      }
    }); 

    $('a[data-toggle=modal-close], .x-close').on('click', function(e) { 
      // destroy vids
      Site.videos.vimDestroyVids(); 
      Site.videos.ytDestroyVids(); 
    }); 
  }, 

  vimeoInit: function() { 
    var tag = document.createElement('script');

    tag.src = "https://player.vimeo.com/api/player.js";
    var firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
  }
};
Site.map = {
	init: function(){
		var $marker_delay = false;
		var $place_name = $('.place-name');
		$('.map-marker').on('mouseover', function(){
			$th = $(this);
			console.log($th.data('place-name'));
			$marker_delay = setTimeout(function(){
				$place_name.css('top', $th.data('top') + '%');
				$place_name.css('left', $th.data('left') + '%');
				$place_name.css('backgroundColor', $th.data('bg'));
				$place_name.html($th.data('place-name')).addClass('show');
			}, 500);
		})
		.on('mouseout', function(){
			$place_name.removeClass('show');
		});
	}
}
Site.values = {
	init: function(){
		$triggerTop = $('.values-scale').offset().top;
		$(window).on('scroll', function(){
			$th = $(this);
			if($th.scrollTop() > $triggerTop - $th.height()/3){
				$('.values-scale .scale').addClass('triggered');
			}
		});
	}
}
Site.flourishes = {
	init : function(){
		var els = '.fadeIn, .shiftIn',
		$el = $('body').find(els);

			function onScroll() {
				$el.each(function() {
					var el = $(this); 

					if (window.pageYOffset + window.innerHeight > el.offset().top) {
						el.addClass('triggered'); 
					} 
				});
			}

		if(!Site.hasTouch && $el.length) { 
			onScroll();

			$(window).on('load scroll resize', function() {
				onScroll(); 
			});
		}
	}
}

$(function(){	
	
	Site.flourishes.init();

	if($('.home-anim').length){
		$('.home-anim').addClass('in');
		window.setTimeout(function(){
			checkCookie();
		},1500);
	}
	else{
		checkCookie();
	}
	if($('.community__audio').length){
		Site.audio.init();
	}
	if($('.feed-recommendations-updates').length){
		Site.carousel.init();
	}
	if($('.interactive-map').length){
		Site.map.init();
	}
	// videos
	if($('a[data-yt-id]').length || $('a[data-vim-id]').length) { 
		Site.videos.init(); 
	}
	if($('a[data-yt-id]').length) { 
		Site.videos.ytInit(); 
	}
	if($('a[data-vim-id]').length) { 
		Site.videos.vimeoInit(); 
	}
	if($('.inline-video-outer').length) { 
		Site.videos.inline(); 
	}
	if($('.values-scale').length){
		Site.values.init();
	}
	$('.menu-icon').on('click', function(){
		$(this).toggleClass('on');
	});

	svgReplace();

	if($('.twentytwenty-container').length){
		$('.twentytwenty-container').each(function(){
			$th = $(this);
			$th.twentytwenty({
				before_label: $th.find('img').eq(0).data('label'),
		      after_label: $th.find('img').eq(1).data('label'),
			});	
		});
	}

	// init search
	$('.search-trigger').on('click', function(){
		$('.site-header').addClass('search-open');
	});
	$('.search-close').on('click', function(){
		$('.site-header').removeClass('search-open');
	})

	var $grid = $('.grid').isotope({});

	var $search_checks = $('#search-checks');
	
	$('#filter-form').on('submit', function(e){
		e.preventDefault();
		$form = $(this);
		$.get($form.attr('action'), $form.serialize(), function(resp){
			
			$query = encodeURI($('#search-field').val());
			$search_checks.html(resp, $query);
			if (history.pushState) {
				 var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + ($query ? '?q=' + $query : '');
				 window.history.pushState({path:newurl},'',newurl);
			}
			runFilter();

		});
	})

	var runFilter = function(){
		// go through all to build filter
		$filters = {};
		$('.filter').each(function(){
			$inner = $(this);
			
			if($inner.is(':checked')){
				
				if($filters[$inner.data('sect')] == undefined){
					$filters[$inner.data('sect')] = [];
				}
				$filters[$inner.data('sect')].push($inner.val()) ;
			}
		});
		// merge filters (or within sects, and between sects)
		$final_filters = [];
		$first = true;
		
		$.each($filters, function(index, value){
			if($first){
				$.each(value, function(i,v){
					$final_filters[i] = v;	
				});
				$first = false;
			}
			else{
				$.each($final_filters, function(i,v){
					$final_filters[i] = []; 
					$.each(value, function(key,val){
						if(Array.isArray(v)){
							for($i = v.length - 1; $i > -1; $i--){
								$final_filters[i].push(v[$i] + val);
							}
						}
						else{
							$final_filters[i].push(v + val);
						}
					});
				});
			}
		});
		$grid.isotope({filter: $final_filters.join()}, layoutApply);
	};

	//lazyload
	var $win = $(window),
	$con = $('#container'),
	$imgs = $("img.lazy");
	// $grid.isotope({
	// 	 onLayout: function() {
	// 		$win.trigger("scroll");
	// 	 }
	// });

	$imgs.lazyload({
		effect : "fadeIn",
		failure_limit: Math.max($imgs.length - 1, 0)
	});

	var layoutApply = function($elems, instance) {
		console.log('layout fired');
		if ( $('.grid').height() == 0 ) { 
			$('#noResultsContainer').fadeIn() 
		} else {
			$('#noResultsContainer').fadeOut()
		}
		$win.trigger("scroll");
	}

	
	$grid.on( 'arrangeComplete', layoutApply );
	// init run 
	$('.filter').on('change', runFilter);
	runFilter();

	$('.close-newsletter-modal').click(function(){
		closeIframe();
	});
});

function svgReplace(){
	/*
	 * Replace all SVG images with inline SVG
	 */
	$('img.svg').each(function(){
	    var $img = jQuery(this);
	    var imgID = $img.attr('id');
	    var imgClass = $img.attr('class');
	    var imgURL = $img.attr('src');

	    $.get(imgURL, function(data) {
	        // Get the SVG tag, ignore the rest
	        var $svg = jQuery(data).find('svg');

	        if(!$svg.attr('viewBox')){
             $svg.attr('viewBox', ('0 0 '
               + $svg.attr('width').match(/[0-9]+\.[0-9]*/) + ' '
               + $svg.attr('height').match(/[0-9]+\.[0-9]*/)));
            }
            
	        // Add replaced image's ID to the new SVG
	        if(typeof imgID !== 'undefined') {
	            $svg = $svg.attr('id', imgID);
	        }
	        // Add replaced image's classes to the new SVG
	        if(typeof imgClass !== 'undefined') {
	            $svg = $svg.attr('class', imgClass+' replaced-svg');
	        }

	        // Remove any invalid XML tags as per http://validator.w3.org
	        $svg = $svg.removeAttr('xmlns:a');

	        // Replace image with new SVG
	        $img.replaceWith($svg);

	    }, 'xml');
	});

}
// parent.closeIframe closes modal
function closeIframe(){
	console.info('closing');
	$('#newsletter-modal-toggle').click();
}
function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    var expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}
function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}
function checkCookie() {
    var hasCookie = getCookie("newsletter-prompt");
    if (hasCookie != "") {
        
    } else {
 		$('#newsletter-modal-toggle').prop('checked','checked');
      setCookie("newsletter-prompt", 'prompted', 3650);
    }
}

window.addEventListener('message', receiveMessage, false);

function receiveMessage(evt)
{
  if (evt.origin === 'http://go.rpa.org/')
  {
    closeIframe();
  }
}